const PressreleaseData = [
    {
        id: 1,
        title: "NEWS PROVIDED BY",
        amnet: "Amnet Digital ->",
        subtitle: "10 Jan, 2024, 10:00 AM IST",
        paragraph: "AUSTIN, DUBAI, HYDERABAD, Jan. 10, 2024/PRNewswire/– - Amnet Digital, a leading Data, Analytics & AI solutions company that is powering businesses and automating operations, today officially launched Swift Insights, an AI-powered analytics platform to accelerate businesses with data-driven insights and make informed decisions through intuitive dashboards.",
        paragraph1: "In today's groundbreaking AI-led innovation era, Swift Insights assists organizations reinvent their business performance with Gen AI and LLMs to uncover new growth opportunities. With Gen AI at its core, Swift Insights expands its AI-powered analytics platform with closed-loop dashboards and advanced reports that solves key business challenges and elevates decision making authority toward customer acquisition to retention and loyalty.",
        sideheading: "Product Key Features",
        des: "AI Analytics: ",
        textelement: "Predictive and Prescriptive analytics using AIML algorithms.",
        des1: "Report Management: ",
        textelement1: "Single platform for enterprise data discovery and report management.",
        des2: "Decision Making: ",
        textelement2: "Make the right decisions at the right time and act fast under dynamic market needs.",
        des3: "Business Insights: ",
        textelement3: "AI-powered reports with actionable insights.",
        des4: "Q&A Analytics: ",
        textelement4: "LLM powered instant business related answers from the data.",
        description2: "Our product launch also expands our offerings with state-of-the-art advanced data security and compliance with greater resilience for your business. The platform also enables proactive measures to minimize tech disruptions for scheduled upgrades and patching that means you will witness less down time.",
        textnrml: "As we launch ",
        boldtext: "Swift Insights, ",
        textnrml1: "Insights, we are excited to bring a transformative solution to businesses and harness the full power of the organizational data. Our AI-powered analytics platform underscores Amnet Digital's commitment to tech innovation and excellence in the Data, Analytics, & AI space said",
        boldtext1: " Krishna Reddy, CEO ",
        textnrml2: "at ",
        boldtext2: "Amnet Digital.",
        textnrml3: "Get actionable insights ",
        boldtext3: " 10x ",
        textnrml4: "faster from your business data stack! Would you like to see how? ",
        textelement5: "Request a demo",
        description5: "We drive and measure our success by the real value we deliver for our clients, partners, communities, and employees. Visit us at",
        link: "www.swiftinsights.ai",
        sideheading1: "About Swift Insights",
        description6: "Swift Insights is an ",
        link1: "AI-powered analytics platform ",
        description7: " that accelerates and transforms your business data into actionable insights to make informed decisions. * Powered by Amnet Digital.",
        sideheading2: "About Amnet Digital",
        description8: "Amnet Digital is a ",
        link2: "Data, Analytics and AI Solutions Company ",
        description9: "that helps businesses to make data driven decisions and automate IT & business operations. We help customers to realize the higher value of their digital transformation initiatives and drive positive business impact leveraging our Analytics & AI digital accelerators. A winner of the ",
        link3: "'Telangana State Awards 2023' ",
        description10: " under Best Services category. ",
        links3: "mailto:careers@amnetdigital.com",
        dst3: " avinash.addi@amnetdigital.com ",
  
    },

]

export default PressreleaseData;