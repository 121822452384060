import { Outlet } from "react-router-dom";
import React from "react";

const Blog = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Blog;
