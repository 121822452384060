import AmnetCompany from "../../images/careers-page/amnet-company.jpg";
import AmnetTeam from "../../images/careers-page/amnet-team.jpg";

const ourCultureData = [
    {
        id : 1,
        amnetIMG : AmnetCompany
    },
    {
        id : 2,
        amnetIMG : AmnetTeam
    }
];

export default ourCultureData;