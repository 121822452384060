import React from "react";
import { Outlet } from "react-router-dom";

const CareerHome= () => {
    return(
        <>
        <Outlet />
        </>
    )
}

export default CareerHome;